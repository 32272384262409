import {Form} from "react-bootstrap";
import {useCallback} from "react";

/**
 *
 * @param {PiktogramWTP} piktogram
 * @param {PiktogramWTPList} piktogramList
 * @param {function(PiktogramWTPList)} setPiktogramList
 * @returns {JSX.Element}
 * @constructor
 */
export default function KalendarzPiktogram({piktogram, piktogramList, setPiktogramList}) {
    const handleChange = useCallback((value, checked) => {
        const x = {...piktogramList}
        x[value] = checked;
        setPiktogramList(x);
    }, [piktogramList, setPiktogramList]);

    return (
        <Form.Check type={"checkbox"} inline={true} id={`piktogram-${piktogram.nazwa}`}>
            <Form.Check.Input type={"checkbox"} value={piktogram.nazwa} checked={piktogramList[piktogram.nazwa]}
                              onChange={e => handleChange(e.target.value, e.target.checked)}/>
            <Form.Check.Label>
                &nbsp;
                <img src={piktogram.image} alt={piktogram.alt} className={"piktogram"}/>
            </Form.Check.Label>
        </Form.Check>
    )
}
