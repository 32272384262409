import wtp_bicycle from "../media/wtp_bicycle.svg"
import wtp_bus from "../media/wtp_bus.svg"
import wtp_info from "../media/wtp_info.svg"
import wtp_parking from "../media/wtp_parking.svg"
import wtp_pop from "../media/wtp_pop.svg"
import wtp_rail from "../media/wtp_rail.svg"
import wtp_subway from "../media/wtp_subway.svg"
import wtp_tram from "../media/wtp_tram.svg"
import wtp_wlt from "../media/wtp_wlt.svg"

export const WTPicon = {
    BUS: wtp_bus,
    TRAM: wtp_tram,
    TRAIN: wtp_rail,
    METRO: wtp_subway,
    INFO: wtp_info,
    PARKING: wtp_parking,
    POP: wtp_pop,
    VETURILO: wtp_bicycle,
    WLT: wtp_wlt,
}
