import {Button, Modal} from "react-bootstrap";
import {useCallback, useState} from "react";
import {decode} from "html-entities";

/**
 * Przycisk usuwania wszystkich komunikatów z listy
 * @param {function()} onReset
 * @param {function()} clearKomunikatyList
 * @returns {JSX.Element}
 * @constructor
 */
export default function DeleteAllButton({onReset, clearKomunikatyList}) {
    const [show, setShow] = useState(false);

    const handleShow = useCallback(() => setShow(true), [setShow]);

    const handleHide = useCallback(() => setShow(false), [setShow]);

    const onDelete = useCallback(() => {
        onReset();
        clearKomunikatyList();
        handleHide();
    }, [onReset, clearKomunikatyList]);

    return <>
        <Button variant={"outline-danger"} onClick={handleShow}>Usuń wszystko</Button>
        <Modal show={show} centered={true} onHide={handleHide}>
            <Modal.Header closeButton={true}>
                Usuwanie listy wszystkich komunikatów
            </Modal.Header>
            <Modal.Body>
                <p>Czy na pewno chcesz usunąć <b>wszystkie</b> komunikaty?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={"secondary"} onClick={handleHide}>NIE</Button>
                <Button variant={"danger"} onClick={onDelete}>TAK</Button>
            </Modal.Footer>
        </Modal>
    </>
}
