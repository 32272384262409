import GeneratorForm from "./GeneratorForm";
import {useList} from "@uidotdev/usehooks"
import KomunikatyList from "./KomunikatyList";
import {useCallback, useEffect, useMemo} from "react";
import {Stack} from "react-bootstrap";
import KomunikatyHTMLField from "./KomunikatyHTMLField";
import Komunikat from "../classes/Komunikat";
import {useLocalStorage} from "usehooks-ts";

/**
 * Generator
 * @returns {JSX.Element}
 */
export default function Generator() {
    const [komunikatyList, {set, push, removeAt, insertAt, updateAt, clear}] = useList();
    const [komunikatyMemory, setKomunikatyMemory, clearKomunikatyMemory] = useLocalStorage("komunikaty", "[]");
    const komunikatyHtml = useMemo(() => Komunikat.listToString(komunikatyList), [komunikatyList]);

    const clearKomunikatyList = useCallback(() => {
        clear();
        clearKomunikatyMemory();
    }, [clear, clearKomunikatyMemory]);

    useEffect(() => {
        if (komunikatyList.length > 0)
            setKomunikatyMemory(JSON.stringify(komunikatyList));
    }, [komunikatyList, setKomunikatyMemory]);

    return (
        <Stack className={"p-3"} gap={4}>
            <GeneratorForm
                addKomunikat={push}
                setKomunikatyList={set}
                clearKomunikatyList={clearKomunikatyList}
                htmlCode={komunikatyHtml}
                komunikatyMemory={komunikatyMemory}
            />
            <KomunikatyList
                komunikatyList={komunikatyList}
                usunKomunikat={removeAt}
                replaceKomunikat={updateAt}
            />
            <KomunikatyHTMLField komunikatyList={komunikatyList} htmlCode={komunikatyHtml}/>
        </Stack>
    )
}
