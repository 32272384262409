import {WTPicon} from "./WTPicon";


export class PiktogramWTP {
    /**
     * Zwraca kod HTML z obrazkiem
     * @returns {JSX.Element}
     */
    get html() {
        return <img className={"format-icon"} src={this.url} alt={this.alt}/>
    }

    /**
     * Adres URL piktogramu na stronie WTP
     * @returns {string}
     */
    get url() {
        return `https://www.wtp.waw.pl/wp-content/themes/wtp-theme/images/wtp_${this._nazwa}.svg`
    }

    /**
     * Plik z piktogramem
     * @returns {WTPicon}
     */
    get image() {
        return this._image;
    }

    /**
     * Tekst alternatywny
     * @returns {string}
     */
    get alt() {
        return this._alt;
    }

    /**
     * Nazwa piktogramu
     * @returns {string}
     */
    get nazwa() {
        return this._nazwa;
    }

    /**
     * Porównuje obiekty piktogramów
     * @override
     * @param {PiktogramWTP} other
     * @returns {boolean}
     */
    equals(other) {
        return this.nazwa === other.nazwa;
    }

    /**
     * Zwraca ciąg znakowy z kodem html piktogramu
     * @override
     * @returns {string}
     */
    toString() {
        return `<img src="${this.url}" alt="${this.alt}" class="change-category-icon">`;
    }

    /**
     * Autobus
     * @returns {PiktogramWTP}
     * @constructor
     */
    static BUS = () => new PiktogramWTP("bus", "autobusy", WTPicon.BUS);

    /**
     * Tramwaj
     * @returns {PiktogramWTP}
     * @constructor
     */
    static TRAM = () => new PiktogramWTP("tram", "tramwaje", WTPicon.TRAM);

    /**
     * Kolej (SKM, KM)
     * @returns {PiktogramWTP}
     * @constructor
     */
    static TRAIN = () => new PiktogramWTP("rail", "kolej", WTPicon.TRAIN);

    /**
     * Metro
     * @returns {PiktogramWTP}
     * @constructor
     */
    static METRO = () => new PiktogramWTP("subway", "metro", WTPicon.METRO);


    /**
     * Informacja
     * @returns {PiktogramWTP}
     * @constructor
     */
    static INFO = () => new PiktogramWTP("info", "informacja", WTPicon.INFO);

    /**
     * Parking P+R/K+R
     * @returns {PiktogramWTP}
     * @constructor
     */
    static PARKING = () => new PiktogramWTP("parking", "parkingi", WTPicon.PARKING);

    /**
     * Punkt Obsługi Pasażerów
     * @returns {PiktogramWTP}
     * @constructor
     */
    static POP = () => new PiktogramWTP("pop", "Punkty Obsługi Pasażerów", WTPicon.POP);

    /**
     * Veturilo
     * @returns {PiktogramWTP}
     * @constructor
     */
    static VETURILO = () => new PiktogramWTP("bicycle", "rowery", WTPicon.VETURILO);

    /**
     * Warszawskie Linie Turystyczne
     * @returns {PiktogramWTP}
     * @constructor
     */
    static WLT = () => new PiktogramWTP("wlt", "Warszawskie Linie Turystyczne", WTPicon.WLT);

    /**
     * Zwraca listę wszystkich piktogramów
     * @returns PiktogramWTP[]
     * @static
     */
    static getAll = () =>
        [
            PiktogramWTP.BUS(),
            PiktogramWTP.TRAM(),
            PiktogramWTP.TRAIN(),
            PiktogramWTP.METRO(),
            PiktogramWTP.INFO(),
            PiktogramWTP.PARKING(),
            PiktogramWTP.POP(),
            PiktogramWTP.VETURILO(),
            PiktogramWTP.WLT()
        ];

    /**
     * Zwraca domyślną listę stanów zaznaczenia piktogramów
     * @returns {PiktogramWTPList}
     * @static
     */
    static getList = () => {
        /**
         * @typedef {Object<"bus"|"tram"|"rail"|"subway"|"info"|"parking"|"pop"|"bicycle"|"wlt",boolean>} PiktogramWTPList
         */
        return {
            bus: false,
            tram: false,
            rail: false,
            subway: false,
            info: false,
            parking: false,
            pop: false,
            bicycle: false,
            wlt: false,
        }
    }

    /**
     * Zwraca piktogram określonego typu wg nazwy
     * @param {"bus","tram","rail","subway","info","parking","pop","bicycle","wlt"} name
     * @returns PiktogramWTP
     */
    static getByName(name) {
        switch (name) {
            case "bus":
                return PiktogramWTP.BUS();
            case "tram":
                return PiktogramWTP.TRAM();
            case "rail":
                return PiktogramWTP.TRAIN();
            case "subway":
                return PiktogramWTP.METRO();
            case "info":
                return PiktogramWTP.INFO();
            case "parking":
                return PiktogramWTP.PARKING();
            case "pop":
                return PiktogramWTP.POP();
            case "bicycle":
                return PiktogramWTP.VETURILO();
            case "wlt":
                return PiktogramWTP.WLT();
            default:
                return undefined;
        }
    }

    /**
     * @private
     * @param {string} nazwa Nazwa pliku z ikoną
     * @param {string} alt Tekst alternatywny
     * @param {WTPicon} image Plik z piktogramem
     */
    constructor(nazwa, alt, image) {
        this._alt = alt;
        this._image = image;
        this._nazwa = nazwa;
    }
}
