import {Button, Modal} from "react-bootstrap";
import {useCallback, useState} from "react";
import {decode} from "html-entities";

/**
 * Przycisk usuwania komunikatu z listy
 * @param {number} index Index komunikatu na liście
 * @param {string} nazwa Nazwa komunikatu
 * @param {function(number)} usunKomunikat Funkcja usuwania komunikatu z listy
 * @returns {JSX.Element}
 * @constructor
 */
export default function DeleteButton({index, nazwa, usunKomunikat}) {
    const [show, setShow] = useState(false);

    const handleShow = useCallback(() => setShow(true), [setShow]);

    const handleHide = useCallback(() => setShow(false), [setShow]);

    const usun = useCallback((index) => {
        usunKomunikat(index);
        handleHide();
    }, [usunKomunikat, handleHide]);

    return <>
        <Button variant={"outline-danger"} onClick={handleShow}>USUŃ</Button>
        <Modal show={show} centered={true} onHide={handleHide}>
            <Modal.Header closeButton={true}>
                Usuwanie komunikatu
            </Modal.Header>
            <Modal.Body>
                <p>Czy na pewno chcesz usunąć komunikat <b>{decode(nazwa)}</b>?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={"secondary"} onClick={handleHide}>NIE</Button>
                <Button variant={"danger"} onClick={() => usun(index)}>TAK</Button>
            </Modal.Footer>
        </Modal>
    </>
}
