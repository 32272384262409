import {Form} from "react-bootstrap";

/**
 * Zwraca textarea z kodem HTML
 * @param {Komunikat[]} komunikatyList
 * @param {string} htmlCode Referencja do pola z kodem HTML
 * @returns {JSX.Element}
 * @constructor
 */
export default function KomunikatyHTMLField({komunikatyList, htmlCode}) {
    const isVisible = komunikatyList.length !== 0 ? {} : {display: "none"};

    return (
        <Form.Control as={'textarea'} value={htmlCode} rows={20} style={isVisible} readOnly={true}/>
    );
}
