import "bootstrap/dist/css/bootstrap.min.css"
import './App.css';
import Header from "./elements/Header";
import Generator from "./elements/Generator";

function App() {
    return (
        <div className="App">
            <Header/>
            <Generator/>
        </div>
    );
}

export default App;
