import {PiktogramWTP} from "./PiktogramWTP";

/**
 * Konwertuje datę na ciąg znakowy
 * @param {Date} data
 * @returns string
 */
function convertDate(data) {
    const mies = ['STY', 'LUT', 'MAR', 'KWI', 'MAJ', 'CZE', 'LIP', 'SIE', 'WRZ', 'PAŹ', 'LIS', 'GRU'];
    const d = data.getDate();
    const m = mies[data.getMonth()];
    const r = data.getFullYear();
    return `${d} ${m} ${r}`;
}

export default class Komunikat {

    get nazwa() {
        return this._nazwa;
    }

    get url() {
        return this._url;
    }

    get data_od() {
        return this._data_od;
    }

    get data_do() {
        return this._data_do;
    }

    get isHighlighted() {
        return this._isHighlighted;
    }

    /**
     * Zwraca datę rozpoczęcia komunikatu w postaci ciągu znakowego
     * @returns {string}
     * @public
     */
    dataOdToString() {
        return convertDate(this.data_od);
    }

    /**
     * Zwraca datę zakończenia komunikatu w postaci ciągu znakowego (pusty, jeżeli daty nie ma)
     * @returns {string}
     * @public
     */
    dataDoToString() {
        if (!this.data_do)
            return "";
        else
            return convertDate(this.data_do);
    }

    /**
     * Definiuje czy komunikat zawiera zakres datowy (data zakończenia istnieje i jest inna niż rozpoczęcia)
     * @returns {boolean}
     * @public
     */
    isRange() {
        if (!this.data_do)
            return false;

        return this.data_od.getTime() !== this.data_do.getTime();
    }

    /**
     * Zwraca listę piktogramów w komunikacie
     * @returns {PiktogramWTP[]}
     */
    get icons() {
        return this._icons;
    }

    /**
     * Zwraca ciąg znakowy z kodem html komunikatu
     * @override
     * @returns {string}
     */
    toString() {
        let daty;
        if (!this.isRange())
            daty = `<div class="change-date">${this.dataOdToString()}</div>`;
        else
            daty = `<div class="change-date-range">
                <div class="change-date"><span class="change-date-prefix">od</span> ${this.dataOdToString()}</div>
                <span class="change-date-arrow">»</span>
                <div class="change-date"><span class="change-date-prefix">do</span> ${this.dataDoToString()}</div>
            </div>`;

        const piktogramy = this.icons.reduce((str, p) => str + p.toString() + "\n\t\t", "");

        return `<li class="change ${this.isHighlighted ? "change-highlighted" : ""} ${this.isRange() ? "change-range" : ""}">
    <a href="${this.url}">
        <div class="change-title">
           ${this.nazwa} 
        </div>
        <div class="change-bar">
                ${daty}
                ${piktogramy}
        </div>
    </a>
</li>`;
    }

    /**
     * Konwertuje listę komunikatów na kod HTML do publikacji na stronie
     * @param {Komunikat[]} komunikatyList
     * @returns string
     * @static
     */
    static listToString(komunikatyList) {
        const komunikaty = komunikatyList.reduce((html, komunikat) => {
            return html + komunikat.toString() + "\n";
        }, "");
        return `<ul class="change-list">
${komunikaty}
</ul>`;
    }

    /**
     * Przekształca JSON w tablicę komunikatów
     * @param {string} string JSON
     * @returns {Komunikat[]}
     */
    static deserialize(string) {
        /** @type Komunikat[] */
        const toReturn = [];
        /**
         * @type {[Object]}
         */
        const obj = JSON.parse(string);
        if (!Array.isArray(obj))
            return toReturn;

        obj.forEach(o => {
            if (o["_nazwa"] !== undefined && o["_url"] !== undefined && o["_data_od"] !== undefined && o["_isHighlighted"] !== undefined) {
                o["_data_do"] = o["_data_do"] === undefined ? undefined : new Date(o["_data_do"]);
                const icons = PiktogramWTP.getList();
                if (Array.isArray(o["_icons"]))
                    o["_icons"].forEach(i => icons[i["_nazwa"]] = true);

                toReturn.push(new Komunikat(o["_nazwa"], o["_url"], new Date(o["_data_od"]), o["_data_do"], o["_isHighlighted"], icons));
            }
        });

        return toReturn;
    }

    /**
     *
     * @param {string} nazwa Nazwa komunikatu
     * @param {string} url Adres URL
     * @param {Date} data_od Termin rozpoczęcia zmiany
     * @param {Date|null} data_do Termin zakończenia zmiany
     * @param {boolean} isHighlighted Wyróżnienie komunikatu
     * @param {PiktogramWTPList} icons Lista piktogramów
     */
    constructor(nazwa, url, data_od, data_do, isHighlighted, icons) {
        this._nazwa = nazwa;
        this._url = url;
        this._data_od = data_od;
        this._data_do = data_do;
        this._isHighlighted = isHighlighted;
        this._icons = Object.keys(icons).reduce((array, i) => {
            if (icons[i]) {
                const pkt = PiktogramWTP.getByName(i);
                if (!!pkt)
                    array.push(pkt);
            }
            return array;
        }, []);
    }
}
