import {Container, Navbar} from "react-bootstrap";

/**
 * Nagłówek strony
 * @returns {JSX.Element}
 */
export default function Header() {
    return (
        <Navbar className="App-header" data-bs-theme={"dark"}>
            <Container>
                <Navbar.Brand className={"mx-auto"}>Generator listy komunikatów WTP</Navbar.Brand>
            </Container>
        </Navbar>
    );
}
