import DatePicker from "react-date-picker";

/**
 * Kalendarz
 * @param {Date} value
 * @param {function(Date)} setValue
 * @param {string} name
 * @param {boolean} required
 * @returns {JSX.Element}
 * @constructor
 */
export default function Kalendarz({value, setValue, name, required = false}) {
    return <DatePicker value={value} onChange={e => setValue(e)} name={name} required={required}
                       showLeadingZeros={true}/>
}
