import {Button, ButtonGroup, FloatingLabel, Form, InputGroup} from "react-bootstrap";
import Kalendarz from "./Kalendarz";
import {PiktogramWTP} from "../classes/PiktogramWTP";
import KalendarzPiktogram from "./KalendarzPiktogram";
import Komunikat from "../classes/Komunikat";
import {useCallback, useMemo, useRef, useState} from "react";

import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import DeleteAllButton from "./DeleteAllButton";

/**
 * Formularz dodawania komunikatu
 * @param {function(Komunikat)} addKomunikat
 * @param {function(Komunikat[])} setKomunikatyList
 * @param {function()} clearKomunikatyList
 * @param {string} htmlCode
 * @param {string} komunikatyMemory
 * @returns {JSX.Element}
 * @constructor
 */
export default function GeneratorForm({
                                          addKomunikat,
                                          setKomunikatyList,
                                          clearKomunikatyList,
                                          htmlCode,
                                          komunikatyMemory
                                      }) {
    const refForm = useRef();
    const [nazwa, setNazwa] = useState("");
    const [url, setUrl] = useState("");
    const [data_od, setData_od] = useState();
    const [data_do, setData_do] = useState();
    const [isHighlighted, setIsHighlighted] = useState(false);
    const [piktogramy, setPiktogramy] = useState(PiktogramWTP.getList());
    const
        defCopyButtonText = "Kopiuj kod HTML do schowka",
        copiedCopyButtonText = "Skopiowano!";
    const [copyButtonText, setCopyButtonText] = useState(defCopyButtonText);

    const onReset = useCallback(() => {
        setNazwa("");
        setUrl("");
        setData_od(null);
        setData_do(null);
        setIsHighlighted(false);
        setPiktogramy(PiktogramWTP.getList());
        refForm.current.reset();
    }, [setNazwa, setUrl, setData_od, setData_do, setIsHighlighted, setPiktogramy, refForm]);

    const onSubmit = useCallback((e) => {
        e.preventDefault(); // Zapobiegamy przeładowaniu strony
        const newKomunikat = new Komunikat(nazwa, url, data_od, data_do, isHighlighted, piktogramy);
        addKomunikat(newKomunikat);
        onReset(); //Wyczyszczenie formularza po dodaniu danych
    }, [addKomunikat, nazwa, url, data_od, data_do, isHighlighted, piktogramy, onReset]);

    const onCopy = useCallback((value) => {
        navigator.clipboard.writeText(value)
            .then(() => {
                setCopyButtonText(copiedCopyButtonText);
                setTimeout(() => setCopyButtonText(defCopyButtonText), 3000);
            });
    }, [setCopyButtonText]);

    const loadList = useCallback(() => {
        console.log(JSON.parse(komunikatyMemory));
        setKomunikatyList(Komunikat.deserialize(komunikatyMemory))
    }, [setKomunikatyList, komunikatyMemory]);

    return (
        <Form ref={refForm} onSubmit={onSubmit} onReset={onReset}>
            <FloatingLabel label={"Tytuł komunikatu"} className={"mb-2"}>
                <Form.Control type={"text"} name={"nazwa"} required={true}
                              value={nazwa} onChange={e => setNazwa(e.target.value)}/>
            </FloatingLabel>
            <FloatingLabel label={"Adres URL"} className={"mb-2"}>
                <Form.Control type={"text"} name={"url"} required={true}
                              value={url} onChange={e => setUrl(e.target.value)}/>
            </FloatingLabel>
            <Form.Label>Zakres ważności komunikatu</Form.Label>
            <InputGroup className={"mb-2"}>
                <Kalendarz name={"data_od"} required={true} value={data_od} setValue={setData_od}/>
                <InputGroup.Text>–</InputGroup.Text>
                <Kalendarz name={"data_do"} value={data_do} setValue={setData_do}/>
            </InputGroup>
            <Form.Check
                type={"switch"}
                label={"Komunikat wyróżniony"}
                id={"highlighted"}
                className={"mb-2"}
                checked={isHighlighted}
                onChange={e => setIsHighlighted(e.target.checked)}
            />
            <div className={"mb-4"}>
                {PiktogramWTP.getAll().map((p) => (
                    <KalendarzPiktogram key={p.nazwa} piktogram={p}
                                        piktogramList={piktogramy} setPiktogramList={setPiktogramy}/>
                ))}
            </div>
            <ButtonGroup style={{width: "100%"}}>
                <Button variant={"success"} type={"submit"}>Dodaj komunikat</Button>
                <Button variant={"outline-dark"} type={"reset"}>Wyczyść</Button>
                <DeleteAllButton onReset={onReset} clearKomunikatyList={clearKomunikatyList}/>
                <Button variant={"outline-primary"} onClick={() => onCopy(htmlCode)}>
                    {copyButtonText}
                </Button>
                <Button variant={"outline-success"} onClick={loadList}>Wczytaj listę</Button>
            </ButtonGroup>
        </Form>
    )
}
