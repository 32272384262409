import {Button, ButtonGroup, ListGroup, ListGroupItem, Stack} from "react-bootstrap";
import {useCallback} from "react";
import {decode} from "html-entities";
import DeleteButton from "./DeleteButton";

/**
 *
 * @param {Komunikat[]} komunikatyList Lista komunikatów
 * @param {function(number)} usunKomunikat Funkcja usuwania komunikatu z określonej pozycji w tabeli
 * @param {function(number, Komunikat)}  replaceKomunikat Funkcja zastępowania komunikatu z określonej pozycji w tabeli
 * @returns {JSX.Element}
 * @constructor
 */
export default function KomunikatyList({komunikatyList, usunKomunikat, replaceKomunikat}) {
    const chPos = useCallback((index, direction) => {
        const tmp = komunikatyList[index];
        const newIndex = index + direction;
        replaceKomunikat(index, komunikatyList[newIndex]);
        replaceKomunikat(newIndex, tmp);
    }, [komunikatyList, replaceKomunikat]);

    const wGore = useCallback((index) => chPos(index, -1), [chPos]);

    const wDol = useCallback((index) => chPos(index, +1), [chPos]);

    return (
        <ListGroup>
            {komunikatyList.map((komunikat, index) => (
                <ListGroupItem key={index} className={komunikat.isHighlighted ? "border border-danger" : ""}>
                    <Stack direction={"horizontal"} gap={1}>
                        <div style={{width: '95%'}}>
                            <div className={"mb-2"}>
                                <a href={komunikat.url} target={"_blank"} className={"h4"}>{decode(komunikat.nazwa)}</a>
                            </div>
                            <Stack direction={"horizontal"}>
                                <div>
                                    {komunikat.dataOdToString()}–{komunikat.dataDoToString()}
                                </div>
                                <Stack direction={"horizontal"} gap={1} className={"ms-auto"}>
                                    {
                                        komunikat.icons.map((i, ikey) => (
                                            <div key={ikey}>
                                                <img src={i.image} alt={i.alt} className={"piktogram"}/>
                                            </div>
                                        ))
                                    }
                                </Stack>
                            </Stack>
                        </div>
                        <ButtonGroup vertical={true} style={{width: "5%"}} size={"sm"}>
                            {index !== 0 &&
                                <Button variant={"outline-dark"} onClick={() => wGore(index)}>&uarr;</Button>
                            }
                            <DeleteButton index={index} nazwa={komunikat.nazwa} usunKomunikat={usunKomunikat}/>
                            {index + 1 < komunikatyList.length &&
                                <Button variant={"outline-dark"} onClick={() => wDol(index)}>&darr;</Button>
                            }
                        </ButtonGroup>
                    </Stack>
                </ListGroupItem>
            ))}
        </ListGroup>
    )
}
